import React from 'react';
import shortId from 'shortid';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';
import { Box, Flex } from './elements';
import { RichText } from '../shared/RichText';

const TitleBox = styled(Box)`
  height: 100%;
  white-space: nowrap;

  &::before {
    border-bottom: 2px solid ${({ theme }) => theme.colors.grey};
    content: '';
    display: block;
    margin-bottom: 5px;
    transition: width 0.7s cubic-bezier(0.17, 0.67, 0.83, 0.67);
    width: ${({ visible }) => (visible ? '100%' : '0%')};
  }
`;

const GalleryItem = ({ item }) => (
  <Flex
    key={shortId.generate()}
    width="100%"
    flexDirection={{ _: 'column', md: 'row' }}
    px={{ _: '5%', md: '0' }}
  >
    <Box
      style={{ boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.75)' }}
      width={{ _: '100%', md: '60%' }}
      height="auto"
      m={{ _: '10px auto', md: '10px 20px' }}
    >
      <Image
        alt={item.primary.image_banner.alt}
        fluid={item.primary.image_banner.localFile.childImageSharp.fluid}
        loading="lazy"
      />
    </Box>
    <VisibilitySensor>
      {({ isVisible }) => (
        <TitleBox
          display={['block', 'inline-block']}
          visible={isVisible}
          m={{ _: '10px 0', md: '10px 20px' }}
          width={{ _: '100%', md: '40%' }}
        >
          {item.primary.title_of_banner.raw.map(raw => (
            <RichText key={shortId.generate()} raw={raw} />
          ))}
          {item.primary.description.raw.map(raw => (
            <RichText key={shortId.generate()} raw={raw} />
          ))}
        </TitleBox>
      )}
    </VisibilitySensor>
  </Flex>
);

GalleryItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default GalleryItem;
