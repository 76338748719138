import React from 'react';
import PropTypes from 'prop-types';
import shortId from 'shortid';
import { Flex } from './elements';
import GalleryItem from './GalleryItem';

const GalleryCarousel = ({ galleryItems }) => (
  <Flex
    flexDirection="column"
    style={{ whiteSpace: 'nowrap' }}
    mb={{ _: '-50px', md: '0' }}
  >
    {galleryItems.map(
      item =>
        item.primary.image_banner.alt !== 'null' && (
          <GalleryItem key={shortId.generate()} item={item} />
        )
    )}
  </Flex>
);

GalleryCarousel.propTypes = {
  galleryItems: PropTypes.array.isRequired
};

export default GalleryCarousel;
