import { graphql } from 'gatsby';
import GalleryPage from '../components/3.GalleryPage';

export default GalleryPage;

export const query = graphql`
  {
    ...LayoutFragment
    ...GalleryItemsFragment
  }
`;
