import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Layout } from './Layout';
import { Box } from './elements';
import GalleryCarousel from './GalleryCarousel';
import galleryBg from '../images/gallery-bg.png';

const GalleryContainer = styled(Box)`
  background-image: url(${galleryBg});
  background-color: rgba(226, 246, 232, 0.3);
`;

const GalleryPage = ({ data }) => {
  const { site_logo: siteLogo } = data.prismicNavigation.data;
  const { localFile: bgLocalFile } = data.prismicNavigation.data.bg_nav;
  const { body: navigation } = data.prismicNavigation.data;
  const navigationPack = { navigation, bgLocalFile };
  const { body: galleryItems } = data.prismicPage.data;

  return (
    <>
      <Layout navigationPack={navigationPack} siteLogo={siteLogo}>
        <GalleryContainer p={4} width="100%">
          <GalleryCarousel galleryItems={galleryItems} />
        </GalleryContainer>
      </Layout>
    </>
  );
};

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default GalleryPage;

export const query = graphql`
  fragment GalleryItemsFragment on Query {
    prismicPage(uid: { eq: "gallery" }) {
      uid
      data {
        title {
          text
        }
        body {
          ... on PrismicPageBodyBannerWithCaption {
            id
            primary {
              description {
                raw {
                  text
                  type
                }
              }
              image_banner {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 700, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              title_of_banner {
                raw {
                  text
                  type
                }
              }
            }
          }
        }
      }
    }
  }
`;
